import { render, staticRenderFns } from "./InsuranceCoordinator.vue?vue&type=template&id=209a97a7&scoped=true&"
import script from "./InsuranceCoordinator.vue?vue&type=script&lang=js&"
export * from "./InsuranceCoordinator.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "209a97a7",
  null
  
)

export default component.exports