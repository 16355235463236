<template>
  <div>

    <div class="m-0 pl-3 pt-4 pb-0 pr-2 d-flex justify-content-between align-items-center">
      <h1 class="ml-3 d-inline-block mt-2 mb-0">Checklist Templates</h1>
      <router-link :to="{name: 'ChecklistItems', params: {id: request.patient_id, tempStage}}">
        <base-button size="lg" class="mr-4 title-btn-padding button-font" type="secondary">
          Checklist Items
        </base-button>
      </router-link>
    </div>

    <div class="container-fluid mb-5">
      <div>
        <div class="tabls-container mt-3" v-loading="loaders.alerts">
          <el-tabs
            class="tab_main"
            type="border-card"
            v-model="request.tab"
            @tab-click="tabClick"
          >
            <el-tab-pane label="Pre-Op" :disabled="loaders.alerts">
              <div v-if="request.tab === '0'">
                <checklist-template
                  :temp_stage="1"
                />
              </div>
            </el-tab-pane>

            <el-tab-pane label="Post-Op" :disabled="loaders.alerts">
              <div v-if="request.tab === '1'">
                <checklist-template
                  :temp_stage="2"
                />
              </div>
            </el-tab-pane>

            <el-tab-pane label="Insurance Coordinators" :disabled="loaders.alerts">
              <div v-if="request.tab === '2'">
                <insurance-coordinator/>
              </div>
            </el-tab-pane>

            <el-tab-pane label="Weight Management" :disabled="loaders.alerts">
              <div v-if="request.tab === '3'">
                <checklist-template
                  :temp_stage="3"
                />
              </div>
            </el-tab-pane>

          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InsuranceCoordinator from "@/views/Components/Templates/InsuranceCoordinator";
import ChecklistTemplate from "@/views/Components/Templates/ChecklistTemplate.vue";
import swal from "sweetalert2";

export default {

  components: {
    InsuranceCoordinator,
    ChecklistTemplate,
  },

  data() {
    return {
      third_checklist_name : 'Weight Management',
      loaders: {
        alerts: false,
        modals: false,
      },
      request: {
        tab: 0,
      },
      tempStage: 1,
    };
  },
  methods: {
    tabClick() {
      this.loaders.alerts = true;

      if (this.request.tab === '0') {
        this.loaders.alerts = false;
        this.tempStage = 1;
      } else if (this.request.tab === '1') {
        this.loaders.alerts = false;
        this.tempStage = 2;
      } else if (this.request.tab === '2') {
        this.tempStage = -1;
        this.loaders.alerts = false;
      } else if (this.request.tab === '3') {
        this.tempStage = 3;
        this.loaders.alerts = false;
      } else {
        this.tempStage = -1;
      }
    },
    saveChecklistItems() {
      let vm = this;
      axios.get(this.$store.getters.getBaseUrl + `/api/third-checklist-name`).then(response => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.third_checklist_name = response.data.data.third_checklist_name
      }).catch(error => {

      }).finally(() => {
        vm.loaders.items = false;
      });
    }

  },
  mounted() {
    swal.fire({
      icon: 'warning',
      text: 'NOTE: Changing of checklist templates may affect automations on your pipeline. If you are using ' +
        'automations on your pipeline, we recommend asking your Baritastic Rep for assistance with template changes.',
      showConfirmButton: true,
      confirmButtonText: 'OK',
      customClass: {
        header: 'templates-listing-note-alert',
      },
      allowOutsideClick: false,
      width: '600px',
      confirmButtonColor: '#2F597A',
    })
  },
};
</script>
<style>
@import "../Alerts/alerts.scss";

.templates-listing-note-alert > .swal2-icon {
  width: 4em !important;
  height: 4em !important;
  color: #f5365c;
  border-color: #f5365c;
}

.no-border-card .card-footer {
  border-top: 0;
}

.clear_txt {
  text-align: left;
  font: normal normal normal 9px/13px Open Sans;
  letter-spacing: 0px;
  color: #FF6421;
  cursor: pointer;
}

.green_btn, .green_btn:hover {
  background: #94B527 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #32325D26;
  border-radius: 6px;
  border: none;
  color: white;
}

.el-select-dropdown {
  width: max-content;
}

</style>
