<template>
  <div>
    <div class="d-flex justify-content-between mt-1 mb-2">
      <div class="ml-3 mt-2">
        <template v-if="request.rowSelected===true">
          <base-button class="red-outline-button pl-5 pr-5 pt-1 pb-1 br-4" @click="$store.commit('showRemoveModal')">
            Delete
          </base-button>
          <base-button
            class="green-outline-button pl-5 pr-5 pt-1 pb-1 br-4 ml-2"
            @click="showConfirmDialog(1)">
            Activate
          </base-button>
          <base-button
            class="black-outline-button pl-5 pr-5 pt-1 pb-1 br-4 ml-2"
            @click="showConfirmDialog(0)">
            Deactivate
          </base-button>
        </template>
      </div>
      <div class="mt-1">
        <template v-if="request.rowSelected===false">
          <router-link class="router-link" :to="{name: 'add-insurance-coordinator'}">
            <base-button class="btn-secondary-action font-weight-light pcl-5 pcr-5 pt-2 pb-2 br-4 mr-3">Add New</base-button>
          </router-link>
        </template>
      </div>
    </div>
    <insurance-coordinator-table
      v-loading="loaders.tables"
      :tableData="response.insuranceCoordinators"
      :tableColumns="tableColumns"
      :pagination="response.pagination"
      :actions="actions"
      @changed-page="changePage"
      @onShow="showInsuranceCoordinator"
      @onEdit="editInsuranceCoordinator"
      @onRemove="promptRemove"
      @onTableRowSelected="onTableRowSelected"
    >
    </insurance-coordinator-table>
    <remove-modal
      :loader="loaders.modals"
      module="insurance coordinator"
      :removeId="request.insuranceCoordinatorId"
      @onRemove="confirmRemove"
      modal-text="You want to remove this Insurance Coordinator?"
    ></remove-modal>

    <confirm-action
      :loader="loaders.modals"
      confirm-id="-1"
      @onConfirm="confirmedDialog"
    ></confirm-action>
  </div>
</template>

<script>
import TableWithActionsAndPagination from "@/components/Tables/TableWithActionsAndPagination";
import RemoveModal from "@/components/Modals/RemoveModal";
import _ from "lodash";
import ConfirmAction from "@/components/Modals/ConfirmAction";

export default {
  name: "InsuranceCoordinator",
  components: {
    InsuranceCoordinatorTable: TableWithActionsAndPagination,
    RemoveModal,
    ConfirmAction
  },
  data() {
    return {
      loaders: {
        tables: false,
      },
      response: {
        insuranceCoordinators: [],
        pagination: {},
      },
      request: {
        insuranceCoordinatorId: "",
        page: 1,
        rowSelected: false,
        rowsSelected: [],
        bulkStatus: 1,
        removeId: -1,
        singleDelete: false,
      },
      actions: [
        {
          src: '/img/icons/buttons/show.svg',
          action: 'show'
        },
        {
          src: '/img/icons/buttons/edit.svg',
          action: 'edit'
        },
        {
          src: '/img/icons/buttons/delete.svg',
          action: 'delete'
        }
      ],
      tableColumns: [
        {
          prop: "name",
          label: "Template Name",
          minWidth: 160,
        },
        {
          prop: "status",
          label: "Status",
          minWidth: 50,
        },
      ]
    }
  },
  methods: {
    changePage(page) {
      this.request.page = page;
      if (this.request.tab === '2') {
        this.getInsuranceCoordinators();
      }
    },

    promptRemove(row) {
      this.request.singleDelete = true;
      this.request.removeId = row.id;
      this.$store.commit('showRemoveModal')
    },

    onTableRowSelected(rows) {
      this.request.rowsSelected = rows;
      this.request.rowSelected = rows.length > 0;
    },

    showConfirmDialog(status) {
      this.$store.commit('showConfirmModal');
      this.request.bulkStatus = status;
    },

    confirmRemove() {
      this.bulkDeleteInsuranceCoordinators();
    },

    confirmedDialog() {
      this.toggleInsuranceCoordinatorsStatus();
    },

    showInsuranceCoordinator(row) {
      this.request.insuranceCoordinator = row;
      this.$router.push({name: 'show-insurance-coordinator', params: {id: this.request.insuranceCoordinator.id}})
      this.$store.commit("showAlertsModal");
    },

    editInsuranceCoordinator(row) {
      this.request.insuranceCoordinator = row;
      this.$router.push({name: 'edit-insurance-coordinator', params: {id: this.request.insuranceCoordinator.id}})
      this.$store.commit("showAlertsModal");
    },

    getInsuranceCoordinators() {
      let vm = this;
      vm.loaders.tables = true;
      axios
        .get(
          `${this.$store.getters.getBaseUrl}/api/insurance-coordinators?page=${this.request.page}`,
        )
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.response.insuranceCoordinators = response.data.data.data;
          vm.response.pagination = response.data.data;
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'ChecklistTemplates',
            message: message
          });
        }).finally(() => {
        vm.loaders.tables = false;
      })
    },

    removeInsuranceCoordinator() {
      let th = this;
      th.loaders.modals = true;
      axios.delete(this.$store.getters.getBaseUrl + '/api/insurance-coordinators/' + this.request.insuranceCoordinatorId, this.insuranceCoordinator).then((response) => {
        th.$store.commit('toggleSessionCheck', true)
        th.$store.commit('updateSessionTime', 0)
        th.$notify.success({
          title: 'Success',
          message: 'Insurance Coordinator Deleted'
        });
        th.$store.commit('hideRemoveModal');
        this.getInsuranceCoordinators();
      }).catch(error => {
        let message = 'Something went wrong please try again in few minutes.';
        if (error.response) {
          let errors = error.response.data.errors;
          let firstError = Object.keys(errors)[0];
          message = errors[firstError][0];
        }
        th.$notify.error({
          title: 'Error',
          message: message
        });
      }).finally(() => {
        th.loaders.modals = false;
      });
    },

    bulkDeleteInsuranceCoordinators() {
      let vm = this;
      let idsToDelete;
      this.request.singleDelete === false ? idsToDelete = _.map(this.request.rowsSelected, 'id') : idsToDelete = [this.request.removeId];
      vm.loaders.modals = true;
      axios.post(`${this.$store.getters.getBaseUrl}/api/insurance-coordinators/bulk-delete`, {
        idsToDelete,
      })
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.getInsuranceCoordinators();
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'InsuranceCoordinatorTemplates',
            message: message
          });
        }).finally(() => {
        vm.loaders.modals = false;
        this.request.singleDelete = false;
        this.$store.commit('hideRemoveModal')
      })
    },

    toggleInsuranceCoordinatorsStatus() {
      let vm = this;
      let idsToToggle = _.map(this.request.rowsSelected, 'id');
      vm.loaders.modals = true;
      axios.post(`${this.$store.getters.getBaseUrl}/api/insurance-coordinators/toggle-status`, {
        idsToToggle,
        status: this.request.bulkStatus,
      })
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.getInsuranceCoordinators();
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'InsuranceCoordinatorTemplates',
            message: message
          });
        }).finally(() => {
        vm.loaders.modals = false;
        this.$store.commit('hideConfirmModal')
      })
    }
  },
  mounted() {
    this.getInsuranceCoordinators();
  },
}
</script>

<style scoped>

</style>
